import React from "react"
import { FunctionComponent } from "react"
import PageLayout from "../components/PageLayout/PageLayout"
import NotFound from "../components/NotFound/NotFound"

const Index: FunctionComponent = () => {
  return (
    <PageLayout>
      <NotFound />
    </PageLayout>
  )
}

export default Index
